import React from "react";
import { useLocation } from "react-router-dom";
import DelayedMessageSpinnerComponent from "../components/Spinner";

export default function ExampleIframeComponent() {
  const location = useLocation();

  // Extracting the path segment after `/examples`
  const iframeSrcPath = location.pathname.replace(
    /\/example(s)?/,
    "/globe-gl-example"
  );

  return (
    <div
      style={{
        maxWidth: "100vw",
        maxHeight: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          zIndex: "1 ",
          background: "white",
        }}
      >
        <DelayedMessageSpinnerComponent key={iframeSrcPath} />
      </div>
      <iframe
        src={iframeSrcPath}
        title="Globe Example"
        style={{
          position: "relative",
          zIndex: "2",
          width: "100vw",
          height: "100vh",
          border: "none",
        }}
      ></iframe>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          background: "white",
          width: "100vw",
          height: "70px",
          zIndex: "3",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span></span>

        <span style={{ fontSize: "1.5rem" }}>
          {formatPathAndCapitalize(location.pathname)}
        </span>
        <a
          style={{ fontSize: "0.8rem", paddingRight: "10px" }}
          href="https://github.com/vasturiano/globe.gl"
        >
          Credits
        </a>
      </div>
    </div>
  );
}

function formatPathAndCapitalize(path: string): string {
  // Split the path into parts
  const parts = path.split("/");

  // Assuming the second part is what we need (index 1)
  const secondPart = parts[2] ?? ""; // Using nullish coalescing to handle undefined

  // Replace hyphens with spaces and capitalize each word
  return secondPart
    .replace(/-/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
