import React, { useState, useEffect } from "react";
import "./Spinner.css";
const DelayedMessageSpinnerComponent: React.FC = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  if (!showMessage) {
    return <div className="spinner"></div>; // Placeholder for your spinner
  }

  return <div>Looks like there's nothing here 😔</div>;
};

export default DelayedMessageSpinnerComponent;
